import React, { useEffect, useState } from "react";
import TopLessons from "../components/TopLessons";
import LessonCard from "../components/LessonCard";
import axiosInstance from "../axiosConfig";

const Lessons = () => {
   const [classTypes, setClassTypes] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axiosInstance.get("/v1/classtype");

            setClassTypes(response.data.class_types);
            setIsLoading(false);
         } catch (err) {
            setIsLoading(false);
            console.log(err);
         }
      };

      fetchData();
   }, []);

   if (isLoading) {
      return <h1>Loading...</h1>;
   }

   return (
      <div>
         <div>
            <p className="text-[#707EAE]">Pages / Lessons</p>
            <h2 className="text-3xl font-bold text-[#2B3674]">Lessons</h2>
         </div>

         <div className="flex mt-8">
            <div className="grid grid-cols-3 gap-4 w-[1090px]">
               {classTypes.map((item, index) => {
                  return (
                     <LessonCard
                        title={item.title}
                        lessonCount={item.lesson_count}
                        imgUrl={item.image_url ?? "https://storage.googleapis.com/sporthub-class-type-image/3.png"}
                        cardImg={item.admin_panel_image ?? "https://storage.googleapis.com/sporthub-post-image/swimming.jpg"}
                        id={item.id}
                     />
                  );
               })}

               {/* <LessonCard title={'test'} lessonCount={0} imgUrl={''} /> */}
            </div>

            {/* <div className="flex-col ml-8 w-[420px]">
               <TopLessons />
            </div> */}
         </div>
      </div>
   );
};

export default Lessons;
